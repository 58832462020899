// src/utils/formatRUT.ts

/**
 * Formatea un RUT chileno agregando puntos y guión.
 * @param rut - RUT sin puntos ni guión (e.g., "11111111K")
 * @returns RUT formateado (e.g., "11.111.111-K")
 */
export const formatRUT = (rut: string): string => {
    if (!rut || rut.length < 2) return rut;
  
    // Convertir a mayúsculas y eliminar puntos y guión
    const cleanRUT = rut.toUpperCase().replace(/\./g, '').replace(/-/g, '');
  
    const body = cleanRUT.slice(0, -1);
    const dv = cleanRUT.slice(-1);
  
    // Insertar puntos cada tres dígitos
    const reversedBody = body.split('').reverse().join('');
    const chunks = reversedBody.match(/.{1,3}/g);
    if (!chunks) return rut;
  
    const formattedBody = chunks.join('.').split('').reverse().join('');
    return `${formattedBody}-${dv}`;
  };
  