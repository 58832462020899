// src/components/users/visit/AddUser.tsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAddUser from '../../../apis/users/useAddUser';
import { validateRUT } from '../../utils/validateRUT';

const AddUser: React.FC = () => {
  const navigate = useNavigate();
  const { addUser, loading, error } = useAddUser();

  const [formData, setFormData] = useState({
    correo: '',
    password: '',
    rut: '',
    nombre: '',
    apellidop: '',
    apellidom: '',
    edad: '', 
    telefono: '',
    acreditado: false,
    grado: '',
    zona: '',
    pais: '',
    perfil: 'Visitante',
    hipertension: false,
    diabetes: false,
    celiaco: false,
    vehiculoPropio: false,
    esposa: false,
    rut_esposa: '',
    nombre_esposa: '',
    apellidop_esposa: '',
    apellidom_esposa: '',
    edad_esposa: '', 
    hipertension_esposa: false,
    diabetes_esposa: false,
    celiaco_esposa: false,
  });

  const [rutError, setRutError] = useState<string>('');
  const [rutEsposaError, setRutEsposaError] = useState<string>('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;

    if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: e.target.checked,
      });
    } else if (name === 'edad' || name === 'edad_esposa') {
      
      if (value === '' || /^[0-9]+$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
      
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    // Validación específica para el campo RUT
    if (name === 'rut') {
      if (value === '') {
        setRutError('El campo RUT es obligatorio');
      } else if (!validateRUT(value)) {
        setRutError('RUT inválido');
      } else {
        setRutError('');
      }
    }

    // Validar el RUT de la esposa si el campo está activo
    if (name === 'rut_esposa') {
      if (formData.esposa) {
        if (value === '') {
          setRutEsposaError('El campo RUT de la esposa es obligatorio');
        } else if (!validateRUT(value)) {
          setRutEsposaError('RUT de la esposa inválido');
        } else {
          setRutEsposaError('');
        }
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validar RUT antes de enviar
    if (!validateRUT(formData.rut)) {
      setRutError('RUT inválido');
      return;
    }

    // Si la esposa está presente, validar también su RUT
    if (formData.esposa && !validateRUT(formData.rut_esposa || '')) {
      setRutEsposaError('RUT de la esposa inválido');
      return;
    }
    
    const edad = formData.edad ? parseInt(formData.edad, 10) : undefined;
    const edad_esposa = formData.edad_esposa ? parseInt(formData.edad_esposa, 10) : undefined;

    if (edad === undefined || isNaN(edad)) {
      alert('Por favor, ingresa una edad válida.');
      return;
    }

    if (formData.esposa && (edad_esposa === undefined || isNaN(edad_esposa))) {
      alert('Por favor, ingresa una edad válida para la esposa.');
      return;
    }

    const { edad: _, edad_esposa: __, ...restFormData } = formData;

    const dataToSubmit = {
      ...restFormData,
      edad: edad as number,
      ...(formData.esposa && { edad_esposa: edad_esposa as number }),
    };

    console.log('Datos a enviar:', dataToSubmit);

    const success = await addUser(dataToSubmit);

    if (success) {
      navigate('/Users/list'); // Redirige a la lista de usuarios después de agregar exitosamente
    }
  };

  const handleCancel = () => {
    navigate('/Users/list'); // Redirige a la lista de usuarios al cancelar
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form className="w-full max-w-md p-6 bg-white rounded shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-4 text-center">Agregar Usuario</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}

        {/* Datos del Usuario */}
        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Correo:
          <input
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Contraseña:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          RUT:
          <input
            type="text"
            name="rut"
            value={formData.rut}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md ${
              rutError ? 'border-red-500' : 'border-gray-600'
            }`}
            placeholder="Ingresa el RUT sin puntos ni guión"
            required
          />
          {rutError && <p className="text-red-500 text-xs mt-1">{rutError}</p>}
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Nombre:
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Apellido Paterno:
          <input
            type="text"
            name="apellidop"
            value={formData.apellidop}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Apellido Materno:
          <input
            type="text"
            name="apellidom"
            value={formData.apellidom}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Edad:
          <input
            type="text"
            name="edad"
            value={formData.edad}
            onChange={handleChange}
            inputMode="numeric"
            pattern="[0-9]*"
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Teléfono:
          <input
            type="text"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Grado:
          <input
            type="text"
            name="grado"
            value={formData.grado}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Zona:
          <input
            type="text"
            name="zona"
            value={formData.zona}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          País:
          <input
            type="text"
            name="pais"
            value={formData.pais}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Perfil:
          <select
            name="perfil"
            value={formData.perfil}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="Administrador">Administrador</option>
            <option value="Visitante">Visitante</option>
            <option value="Encargado de Despensa">Encargado de Despensa</option>
            <option value="Encargado de Custodia">Encargado de Custodia</option>
            <option value="Encargado de Vehículos">Encargado de Vehículos</option>
          </select>
        </label>

        {/* Condiciones de Salud */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="hipertension"
              checked={formData.hipertension}
              onChange={handleChange}
              className="mr-2"
            />
            Hipertensión
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="diabetes"
              checked={formData.diabetes}
              onChange={handleChange}
              className="mr-2"
            />
            Diabetes
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="celiaco"
              checked={formData.celiaco}
              onChange={handleChange}
              className="mr-2"
            />
            Celíaco
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="vehiculoPropio"
              checked={formData.vehiculoPropio}
              onChange={handleChange}
              className="mr-2"
            />
            Vehículo Propio
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="acreditado"
              checked={formData.acreditado}
              onChange={handleChange}
              className="mr-2"
            />
            Acreditado
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="esposa"
              checked={formData.esposa}
              onChange={handleChange}
              className="mr-2"
            />
            Asiste Esposa
          </label>
        </div>

        {/* Datos de la Esposa */}
        {formData.esposa && (
          <>
            <div className="mt-4 text-lg font-semibold text-gray-700">Datos de la Esposa</div>
            <label className="block mb-2 text-sm font-semibold text-gray-600">
              RUT:
              <input
                type="text"
                name="rut_esposa"
                value={formData.rut_esposa}
                onChange={handleChange}
                className={`mt-1 block w-full p-2 border rounded-md ${
                  rutEsposaError ? 'border-red-500' : 'border-gray-600'
                }`}
                placeholder="Ingresa el RUT sin puntos ni guión"
                required
              />
              {rutEsposaError && <p className="text-red-500 text-xs mt-1">{rutEsposaError}</p>}
            </label>
            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Nombre:
              <input
                type="text"
                name="nombre_esposa"
                value={formData.nombre_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
                required
              />
            </label>
            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Apellido Paterno:
              <input
                type="text"
                name="apellidop_esposa"
                value={formData.apellidop_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
                required
              />
            </label>
            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Apellido Materno:
              <input
                type="text"
                name="apellidom_esposa"
                value={formData.apellidom_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
                required
              />
            </label>
            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Edad:
              <input
                type="text"
                name="edad_esposa"
                value={formData.edad_esposa}
                onChange={handleChange}
                inputMode="numeric"
                pattern="[0-9]*"
                className="mt-1 block w-full p-2 border rounded-md"
              />
            </label>
            <div className="grid grid-cols-2 gap-4 mt-4">
              <label className="flex items-center text-sm font-semibold text-gray-600">
                <input
                  type="checkbox"
                  name="hipertension_esposa"
                  checked={formData.hipertension_esposa}
                  onChange={handleChange}
                  className="mr-2"
                />
                Hipertensión
              </label>
              <label className="flex items-center text-sm font-semibold text-gray-600">
                <input
                  type="checkbox"
                  name="diabetes_esposa"
                  checked={formData.diabetes_esposa}
                  onChange={handleChange}
                  className="mr-2"
                />
                Diabetes
              </label>
              <label className="flex items-center text-sm font-semibold text-gray-600">
                <input
                  type="checkbox"
                  name="celiaco_esposa"
                  checked={formData.celiaco_esposa}
                  onChange={handleChange}
                  className="mr-2"
                />
                Celíaca
              </label>
            </div>
          </>
        )}

        {/* Botón de Enviar */}
        <button
          type="submit"
          className="w-full py-2 mt-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-500"
          disabled={loading}
        >
          {loading ? 'Agregando...' : 'Agregar Usuario'}
        </button>
        {/* Botón de Cancelar */}
        <button
          type="button"
          onClick={handleCancel}
          className="w-full py-2 mt-2 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-400"
        >
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default AddUser;
