import React from 'react';
import { useNavigate } from 'react-router-dom';

const people = [
    { id: 1, name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    { id: 2, name: 'Michael Scott', title: 'Regional Manager', email: 'michael.scott@dundermifflin.com', role: 'Admin' },
    { id: 3, name: 'Pam Beesly', title: 'Office Administrator', email: 'pam.beesly@dundermifflin.com', role: 'Member' },
    // Agrega más datos de prueba aquí
];

const ViewUsersRoles = () => {
    const navigate = useNavigate();

    const handleAddUserRedirect = () => {
        navigate('/AddUser');
    };

    const handleEditRedirect = (id: number) => {
        navigate(`/edit_user_rol/${id}`);
    };

    const handleDetailRedirect = (id: number) => {
        navigate(`/detail_user_rol/${id}`);
    };


    return (
        <div className="p-6 lg:p-8 bg-gray-100 min-h-screen">
            <div className="text-center">
                <h1 className="text-3xl font-bold text-gray-800">Usuarios Administradores del sistema</h1>
                <p className="mt-2 text-lg text-gray-600">Lista de usuarios registrados en el sistema.</p>
            </div>

            <div className="mt-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <h2 className="text-lg font-semibold text-gray-900">Usuarios</h2>
                    <button
                        onClick={handleAddUserRedirect}
                        className="mt-4 sm:mt-0 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
                    >
                        Agregar Usuario
                    </button>
                </div>

                <div className="mt-8 overflow-hidden border border-gray-300 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Puesto</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Correo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rol</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {people.map((person) => (
                                <tr key={person.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{person.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.title}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{person.role}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                        <button
                                            onClick={() => handleDetailRedirect(person.id)}
                                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                                        >
                                            Ver
                                        </button>
                                        <button
                                            onClick={() => handleEditRedirect(person.id)}
                                            className="text-indigo-600 hover:text-indigo-900"
                                        >
                                            Editar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default ViewUsersRoles;
