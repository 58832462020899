import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const users = Array.from({ length: 200 }, (_, i) => ({
  id: i + 1,
  name: `User ${i + 1}`,
  title: `Title ${i + 1}`,
  email: `user${i + 1}@example.com`,
  role: i % 2 === 0 ? 'Admin' : 'Member',
}));

const ViewUsers = () => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [searchTerm, setSearchTerm] = useState('');

    const filteredUsers = users.filter(user =>
        user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    const currentUsers = filteredUsers.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleAddUserRedirect = () => {
        navigate('/AddUser');
    };

    const handleEditRedirect = (id: number) => {
        navigate(`/edit_user/${id}`);
    };

    const handleDetailRedirect = (id: number) => {
        navigate(`/detail_user/${id}`);
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    return (
        <div className="p-6 lg:p-8 bg-gray-100 min-h-screen">
            <div className="text-center">
                <h1 className="text-3xl font-bold text-gray-800">Usuarios del sistema</h1>
                <p className="mt-2 text-lg text-gray-600">Lista de usuarios registrados en el sistema.</p>
            </div>

            <div className="mt-8 px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between mb-4">
                    <h2 className="text-lg font-semibold text-gray-900">Usuarios</h2>
                    <button
                        onClick={handleAddUserRedirect}
                        className="mt-4 sm:mt-0 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
                    >
                        Agregar Usuario
                    </button>
                </div>

                {/* Buscador */}
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="Buscar usuario..."
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setCurrentPage(1); // Reiniciar a la primera página al buscar
                        }}
                        className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    />
                </div>

                {/* Vista de tabla en escritorio */}
                <div className="hidden lg:block mt-8 overflow-hidden border border-gray-300 shadow sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nombre</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Puesto</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Correo</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Rol</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {currentUsers.map((user) => (
                                <tr key={user.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{user.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.title}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.role}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                        <button
                                            onClick={() => handleDetailRedirect(user.id)}
                                            className="text-indigo-600 hover:text-indigo-900 mr-4"
                                        >
                                            Ver
                                        </button>
                                        <button
                                            onClick={() => handleEditRedirect(user.id)}
                                            className="text-indigo-600 hover:text-indigo-900"
                                        >
                                            Editar
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Vista de tarjetas en móvil */}
                <div className="lg:hidden mt-8 grid gap-6">
                    {currentUsers.map((user) => (
                        <div key={user.id} className="bg-white border border-gray-300 p-4 rounded-lg shadow-md">
                            <h3 className="text-lg font-semibold text-gray-900">{user.name}</h3>
                            <p className="text-sm text-gray-500">{user.title}</p>
                            <p className="text-sm text-gray-500">{user.email}</p>
                            <p className="text-sm text-gray-500">{user.role}</p>
                            <div className="mt-4 flex space-x-4">
                                <button
                                    onClick={() => handleDetailRedirect(user.id)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                >
                                    Ver
                                </button>
                                <button
                                    onClick={() => handleEditRedirect(user.id)}
                                    className="text-indigo-600 hover:text-indigo-900"
                                >
                                    Editar
                                </button>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Controles de paginación */}
                <div className="mt-6 flex justify-between items-center">
                    <button
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                        className="bg-indigo-600 text-white px-4 py-2 rounded-md disabled:opacity-50"
                    >
                        Anterior
                    </button>
                    <span className="text-gray-600">Página {currentPage} de {totalPages}</span>
                    <button
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                        className="bg-indigo-600 text-white px-4 py-2 rounded-md disabled:opacity-50"
                    >
                        Siguiente
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ViewUsers;
