// src/components/vehicles/VehicleList.tsx
import React from 'react';
import { useAppContext } from '../../contexts/GlobalStateContext';

const VehicleList: React.FC = () => {
  const { vehicles, removeVehicle } = useAppContext();

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold text-center mb-6">Listado de Vehículos</h2>

      {/* Vista de Tarjetas para dispositivos móviles */}
      <div className="block lg:hidden space-y-4">
        {vehicles.length > 0 ? (
          vehicles.map((vehicle) => (
            <div key={vehicle.id} className="bg-white p-4 rounded-lg shadow-lg">
              <h3 className="text-xl font-semibold text-gray-800">{vehicle.marca} {vehicle.modelo}</h3>
              <p className="text-gray-600"><strong>Patente:</strong> {vehicle.patente}</p>
              <p className="text-gray-600"><strong>Asignado a usuario ID:</strong> {vehicle.userId || 'No asignado'}</p>
              <button
                onClick={() => removeVehicle(vehicle.id)}
                className="mt-4 inline-flex items-center rounded-md bg-red-500 px-4 py-2 text-sm font-semibold text-white hover:bg-red-600"
              >
                Eliminar
              </button>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-600">No hay vehículos registrados.</p>
        )}
      </div>

      {/* Vista de Tabla para dispositivos grandes */}
      <div className="hidden lg:block">
        {vehicles.length > 0 ? (
          <table className="min-w-full bg-white rounded-lg shadow-lg overflow-hidden">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Marca</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Modelo</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Patente</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Asignado a usuario ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase">Acciones</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {vehicles.map((vehicle) => (
                <tr key={vehicle.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{vehicle.marca}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{vehicle.modelo}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{vehicle.patente}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{vehicle.userId || 'No asignado'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => removeVehicle(vehicle.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-600">No hay vehículos registrados.</p>
        )}
      </div>
    </div>
  );
};

export default VehicleList;
