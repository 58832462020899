// src/contexts/GlobalStateContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export interface LoginResponse {
  user: {
    user_id: number;
    correo: string;
    rut: string;
    nombre: string;
    apellidop: string;
    apellidom: string;
    edad: number;
    telefono: string;
    iglesia: string;
    acreditado: boolean;
    grado: string;
    zona: string;
    pais: string;
    perfil: string;
    hipertension: boolean;
    diabetes: boolean;
    celiaco: boolean;
    vehiculoPropio: boolean;
    esposa: boolean;
    rut_esposa: string;
    nombre_esposa: string;
    apellidop_esposa: string;
    apellidom_esposa: string;
    edad_esposa: number;
    hipertension_esposa: boolean;
    diabetes_esposa: boolean;
    celiaco_esposa: boolean;
  };
}

export interface CustodySpace {
  id: number;
  location: string;
  capacity: 'Maleta' | 'Bolso de mano';
  occupied: boolean;
}

export interface Vehicle {
  id: number;
  marca: string;
  modelo: string;
  patente: string;
  userId: number | null;
}

interface AppContextInterface {
  isAuthenticated: boolean;
  user: LoginResponse | null;
  login: (email: string, password: string) => Promise<LoginResponse | null>;
  logout: () => void;
  custodySpaces: CustodySpace[];
  addCustodySpace: (newSpace: Omit<CustodySpace, 'id'>) => void;
  assignSpace: (id: number) => void;
  removeCustodySpace: (id: number) => void;
  vehicles: Vehicle[];
  addVehicle: (vehicleData: Omit<Vehicle, 'id'>) => void;
  assignVehicle: (vehicleId: number, userId: number) => void;
  removeVehicle: (id: number) => void;
}

const AppContext = createContext<AppContextInterface | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<LoginResponse | null>(null);
  const [custodySpaces, setCustodySpaces] = useState<CustodySpace[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const navigate = useNavigate();

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para la Custodia de equipaje
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const savedSpaces = localStorage.getItem('custodySpaces');
    if (savedSpaces) {
      setCustodySpaces(JSON.parse(savedSpaces));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('custodySpaces', JSON.stringify(custodySpaces));
  }, [custodySpaces]);

  const addCustodySpace = (newSpace: Omit<CustodySpace, 'id'>) => {
    const spaceWithId: CustodySpace = { ...newSpace, id: Date.now() };
    setCustodySpaces((prevSpaces) => [...prevSpaces, spaceWithId]);
  };

  const assignSpace = (id: number) => {
    const updatedSpaces = custodySpaces.map(space =>
      space.id === id ? { ...space, occupied: true } : space
    );
    setCustodySpaces(updatedSpaces);
    localStorage.setItem('custodySpaces', JSON.stringify(updatedSpaces));
  };

  const removeCustodySpace = (id: number) => {
    const updatedSpaces = custodySpaces.filter((space) => space.id !== id);
    setCustodySpaces(updatedSpaces);
    localStorage.setItem('custodySpaces', JSON.stringify(updatedSpaces));
  };

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Vehículos
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const storedVehicles = localStorage.getItem('vehicles');
    if (storedVehicles) {
      setVehicles(JSON.parse(storedVehicles));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('vehicles', JSON.stringify(vehicles));
  }, [vehicles]);

  const addVehicle = (vehicleData: Omit<Vehicle, 'id'>) => {
    const newVehicle = { ...vehicleData, id: Date.now() };
    setVehicles((prevVehicles) => [...prevVehicles, newVehicle]);
  };

  const assignVehicle = (vehicleId: number, userId: number) => {
    const updatedVehicles = vehicles.map(vehicle =>
      vehicle.id === vehicleId ? { ...vehicle, userId } : vehicle
    );
    setVehicles(updatedVehicles);
    localStorage.setItem('vehicles', JSON.stringify(updatedVehicles));
  };

  const removeVehicle = (id: number) => {
    const updatedVehicles = vehicles.filter((vehicle) => vehicle.id !== id);
    setVehicles(updatedVehicles);
  };

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Login y Logout
  
  ++++++++++++++++++++++++++++++++++++++++++*/
  const login = async (correo: string, password: string): Promise<LoginResponse | null> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_RAILWAY_API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ correo, password }),
      });
      
      console.log("url" + `${process.env.REACT_APP_RAILWAY_API_URL}/auth/login`);
      console.log("respose "+ JSON.stringify(response));

      
      if (!response.ok) throw new Error('Login failed');

      const data: LoginResponse = await response.json();
      setUser(data); // Guarda el objeto `user` completo en el estado
      setIsAuthenticated(true);
      
      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('user');
    navigate('/');
  };

  return (
    <AppContext.Provider value={{
      isAuthenticated,
      user,
      login,
      logout,
      custodySpaces,
      addCustodySpace,
      assignSpace,
      removeCustodySpace,
      vehicles,
      addVehicle,
      assignVehicle,
      removeVehicle
    }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error('useAppContext debe ser utilizado dentro de un AppProvider');
  return context;
};
