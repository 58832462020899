import { useState, useEffect } from 'react';
import { User } from '../../types/User'; // Adjust the path as necessary

const useUsers = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/users');
        if (!response.ok) throw new Error('Error al cargar los usuarios');
        const data: User[] = await response.json();
        setUsers(data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };
    fetchUsers();
  }, []);

  // Función para obtener un usuario por ID
  const fetchUserById = async (id: number): Promise<User | null> => {
    setLoading(true);
    try {
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${id}`);
      if (!response.ok) throw new Error('Error al obtener el usuario');
      const data: User = await response.json();
      setSelectedUser(data);
      return data;
    } catch (error) {
      setError((error as Error).message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Función para eliminar un usuario
  const deleteUser = async (id: number) => {
    try {
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${id}`, {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
      });
      if (!response.ok) throw new Error('Error al eliminar el usuario.');
      setUsers(users.filter(user => user.user_id !== id));
    } catch (error) {
      setError('No se pudo eliminar el usuario.');
    }
  };

  // Función para editar un usuario
  const editUser = async (id: number, updatedUser: User) => {
    setLoading(true);
    try {
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updatedUser),
      });
      if (!response.ok) throw new Error('Error al editar el usuario.');
      const editedUser = await response.json();
      setUsers(users.map(user => (user.user_id === id ? editedUser : user)));
    } catch (error) {
      setError('No se pudo editar el usuario.');
    } finally {
      setLoading(false);
    }
  };

  return { users, selectedUser, loading, error, fetchUserById, deleteUser, editUser };
};

export default useUsers;
