// src/components/users/visit/DetailUser.tsx

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useUsers from '../../../apis/users/useUsers';
import { User } from '../../../types/User';
import { formatRUT } from '../../utils/formatRUT'; // Importar la función

const DetailUser: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id || '0', 10);
  const navigate = useNavigate();
  const { fetchUserById, loading, error } = useUsers();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    const getUser = async () => {
      const fetchedUser = await fetchUserById(userId);
      if (!fetchedUser) {
        // Manejar usuario no encontrado
        navigate('/Users'); // Redirige a la lista de usuarios o muestra un mensaje de "no encontrado"
      } else {
        setUser(fetchedUser);
      }
    };
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <p className="text-gray-700">Cargando...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  if (!user) {
    return null; // Ya manejado por navigate
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-2xl w-full bg-white p-6 rounded-lg shadow-md">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-semibold leading-7 text-gray-900">Información del Usuario</h3>
          <p className="mt-1 text-sm text-gray-500">Detalles personales y de cuenta.</p>
        </div>

        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* Información básica del usuario */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Nombre Completo</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {user.nombre} {user.apellidop ?? ''} {user.apellidom ?? ''}
              </dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Correo Electrónico</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.correo}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">RUT</dt>
              <dd className="text-sm text-gray-700 col-span-2">{formatRUT(user.rut)}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Perfil</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.perfil}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Teléfono</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.telefono ?? 'N/A'}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Hospedaje Asignado</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.hospedaje ?? 'N/A'}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Mesa Asignada</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.mesa ?? 'N/A'}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Vehículo Propio</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.vehiculo ? 'Sí' : 'No'}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Auto Asignado</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.auto ?? 'N/A'}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Custodia</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.custodia ?? 'N/A'}</dd>
            </div>

            {/* Condiciones Médicas */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Condiciones Médicas</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {user.condiciones_medicas?.join(', ') || 'N/A'}
              </dd>
            </div>

            {/* Información de la Esposa */}
            {user.esposa && (
              <>
                <div className="py-4">
                  <h4 className="text-lg font-semibold text-gray-900">Información de Esposa</h4>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Nombre</dt>
                  <dd className="text-sm text-gray-700 col-span-2">{user.nombre_esposa ?? 'N/A'}</dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">RUT</dt>
                  <dd className="text-sm text-gray-700 col-span-2">{user.rut_esposa ? formatRUT(user.rut_esposa) : 'N/A'}</dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Edad</dt>
                  <dd className="text-sm text-gray-700 col-span-2">{user.edad_esposa ?? 'N/A'}</dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Enfermedad Base</dt>
                  <dd className="text-sm text-gray-700 col-span-2">
                    {user.enfermedad_base_esposa?.join(', ') || 'N/A'}
                  </dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Fotografía</dt>
                  <dd className="text-sm text-gray-700 col-span-2">
                    {user.fotografia_esposa ? (
                      <img
                        src={user.fotografia_esposa}
                        alt="Fotografía Esposa"
                        className="w-20 h-20 rounded-md"
                      />
                    ) : (
                      'N/A'
                    )}
                  </dd>
                </div>
              </>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default DetailUser;
