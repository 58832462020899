import React, { useState } from 'react';
import { CustodySpace, useAppContext } from '../../contexts/GlobalStateContext';

const CustodySections: React.FC = () => {
  const { custodySpaces, addCustodySpace, removeCustodySpace } = useAppContext();

  const [newSpace, setNewSpace] = useState<Omit<CustodySpace, 'id'>>({
    location: '',
    capacity: 'Maleta',
    occupied: false,
  });

  const [searchTerm, setSearchTerm] = useState('');

  // Filtrar espacios según el término de búsqueda
  const filteredSpaces = custodySpaces.filter(space =>
    space.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
    space.capacity.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleAddSpace = (e: React.FormEvent) => {
    e.preventDefault();
    addCustodySpace(newSpace);
    setNewSpace({ location: '', capacity: 'Maleta', occupied: false });
  };

  const handleRemoveSpace = (id: number) => {
    removeCustodySpace(id);
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-semibold mb-4">Secciones de Custodia</h2>

      {/* Buscador */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar espacio..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full px-4 py-2 border rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
        />
      </div>

      {/* Formulario para agregar espacios de custodia */}
      <form onSubmit={handleAddSpace} className="bg-white p-4 rounded shadow mb-6">
        <h3 className="text-lg font-semibold mb-4">Agregar Espacio de Custodia</h3>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <div>
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">Ubicación</label>
            <input
              type="text"
              id="location"
              name="location"
              value={newSpace.location}
              onChange={(e) => setNewSpace({ ...newSpace, location: e.target.value })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>

          <div>
            <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">Capacidad</label>
            <select
              id="capacity"
              name="capacity"
              value={newSpace.capacity}
              onChange={(e) => setNewSpace({ ...newSpace, capacity: e.target.value as 'Maleta' | 'Bolso de mano' })}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            >
              <option value="Maleta">Maleta</option>
              <option value="Bolso de mano">Bolso de mano</option>
            </select>
          </div>
        </div>

        <button
          type="submit"
          className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
        >
          Agregar Espacio
        </button>
      </form>

      {/* Lista de espacios de custodia */}
      <div className="bg-white p-4 rounded shadow">
        <h3 className="text-lg font-semibold mb-4">Espacios Disponibles</h3>

        {filteredSpaces.length === 0 ? (
          <p className="text-gray-500">No se encontraron espacios de custodia.</p>
        ) : (
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:hidden">
            {filteredSpaces.map((space) => (
              <div key={space.id} className="bg-white border border-gray-300 p-4 rounded-lg shadow-md">
                <h3 className="text-lg font-semibold text-gray-900">ID: {space.id}</h3>
                <p className="text-sm text-gray-500">Ubicación: {space.location}</p>
                <p className="text-sm text-gray-500">Capacidad: {space.capacity}</p>
                <p className="text-sm text-gray-500">Ocupado: {space.occupied ? 'Sí' : 'No'}</p>
                <button
                  onClick={() => handleRemoveSpace(space.id)}
                  className="mt-4 text-red-600 hover:text-red-800"
                >
                  Eliminar
                </button>
              </div>
            ))}
          </div>
        )}

        {/* Vista de tabla en escritorio */}
        <div className="hidden lg:block">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID Espacio</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ubicación</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Capacidad</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ocupado</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Acciones</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredSpaces.map((space) => (
                <tr key={space.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{space.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{space.location}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{space.capacity}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{space.occupied ? 'Sí' : 'No'}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleRemoveSpace(space.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      Eliminar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default CustodySections;
