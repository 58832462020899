import React from 'react';

const UserRolProfile: React.FC = () => {
  const user = {
    email: 'camila@castros.cl',
    nombre: 'Camila',
    apellidoPaterno: 'Castro',
    apellidoMaterno: 'Vergara',
    rut: '99.9999.999-9',
    perfil: 'Administrador',
    hospedaje: 'Avenida Avenida',
    teléfono: '+56976424587',
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-2xl w-full bg-white p-6 rounded-lg shadow-md">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-semibold leading-7 text-gray-900">Información del Usuario</h3>
          <p className="mt-1 text-sm text-gray-500">Detalles personales y de cuenta.</p>
        </div>

        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* Información básica del usuario */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Nombre Completo</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {user.nombre} {user.apellidoPaterno} {user.apellidoMaterno}
              </dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Correo Electrónico</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.email}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">RUT</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.rut}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900"></dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.perfil}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Teléfono</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.teléfono}</dd>
            </div>
 
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserRolProfile;
