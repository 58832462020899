// src/utils/validateRUT.ts
export const validateRUT = (rut: string): boolean => {
    if (!rut || rut.length < 2) return false;
  
    // Convertir a mayúsculas y eliminar puntos y guion
    const cleanRUT = rut.toUpperCase().replace(/\./g, '').replace(/-/g, '');
    
    const body = cleanRUT.slice(0, -1);
    const dv = cleanRUT.slice(-1);
  
    if (!/^\d+$/.test(body)) return false;
  
    let suma = 0;
    let multiplier = 2;
  
    for (let i = body.length - 1; i >= 0; i--) {
      suma += parseInt(body.charAt(i), 10) * multiplier;
      multiplier = multiplier < 7 ? multiplier + 1 : 2;
    }
  
    const resto = suma % 11;
    let dvCalculado: string;
  
    if (resto === 1) {
      dvCalculado = 'K';
    } else if (resto === 0) {
      dvCalculado = '0';
    } else {
      dvCalculado = (11 - resto).toString();
    }
  
    return dv === dvCalculado;
  };
  