// src/apis/custody/getCustodySpaces.ts
import { CustodySpace } from "../../contexts/GlobalStateContext";

export const getCustodySpaces = async (): Promise<CustodySpace[]> => {
    try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/custodia', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`Error fetching custody spaces: ${response.statusText}`);
        }

        const data = await response.json();
        if (Array.isArray(data)) {
            // Procesar cada espacio y obtener datos del usuario si está ocupado
            const spacesWithUserData = await Promise.all(data.map(async (item) => {
                const space = {
                    id: item.id,
                    ubicacion: item.ubicacion,
                    capacidad: item.capacidad,
                    ocupado: item.ocupado,
                };

                // Si el espacio está ocupado, intentar obtener los datos del usuario
                if (item.ocupado && item.user?.user_id) {
                    try {
                        const userResponse = await fetch(
                            `https://agy-estudio-biblico-production.up.railway.app/users/${item.user.user_id}`
                        );

                        if (userResponse.ok) {
                            const userData = await userResponse.json();
                            return {
                                ...space,
                                user: {
                                    user_id: userData.user_id,
                                    correo: userData.correo,
                                    nombre: userData.nombre,
                                    apellidop: userData.apellidop,
                                    apellidom: userData.apellidom,
                                    rut: userData.rut
                                }
                            };
                        }
                    } catch (error) {
                        console.error(`Error fetching user data for space ${item.id}:`, error);
                    }
                }

                return space;
            }));

            return spacesWithUserData;
        } else {
            throw new Error('Unexpected response format');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export default getCustodySpaces;