import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { PlusCircleIcon, ClipboardIcon, ArchiveBoxIcon } from '@heroicons/react/24/outline';

const custodyOptions = [
  { name: 'Espacios  de Custodia', href: 'create_section', icon: PlusCircleIcon },
  { name: 'Recibir Equipaje', href: 'receive_luggage', icon: ClipboardIcon },
  { name: 'Entregar Equipaje', href: 'deliver_luggage', icon: ArchiveBoxIcon },
];

const CustodyPage: React.FC = () => {
  return (
    <div className="p-6 bg-gray-50">
      <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Custodia</h1>

      {/* Menú de navegación de opciones en cuadrícula */}
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-3 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
          {custodyOptions.map((option) => (
            <Link
              key={option.name}
              to={option.href}
              className="flex items-center gap-x-2.5 p-4 px-6 text-sm font-semibold text-gray-900 hover:bg-gray-100 sm:justify-center"
            >
              <option.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
              {option.name}
            </Link>
          ))}
        </div>
      </div>

      {/* Renderizado del subcomponente según la ruta */}
      <div className="mt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default CustodyPage;
