// src/apis/users/QrAPI.tsx

interface RegisterEntryData {
    tipo_ingreso: string; // Puede ser "ENTRADA" o "SALIDA"
  }
  
  export const registerEntry = async (userId: string, data: { tipo_ingreso: string }) => {
    try {
      const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/registros/${userId}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return response; // Devuelve la respuesta completa
    } catch (error) {
      console.error('Error al registrar la entrada:', error);
      throw error;
    }
  };

export default registerEntry;