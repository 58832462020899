import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';


  const EditUserRol = () => {
      const [hasSpouse, setHasSpouse] = useState(false);

      const navigate = useNavigate();

      const handleCancel = () => {
        navigate('/Users'); // Redirige al listado de usuarios
    };

  
      return (
          <div className="p-8 bg-gray-100 min-h-screen">
              <h1 className="text-3xl font-bold text-gray-800 text-center">Edición de Usuario con Rol</h1>
              
              <form className="mt-8 space-y-12">
                  {/* Información Personal */}
                  <div className="border-b border-gray-300 pb-8">
                      <h2 className="text-lg font-semibold text-gray-700">Información Personal</h2>
                      
                      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">

                      <div>
                              <label className="block text-sm font-medium text-gray-700">Nombre</label>
                              <input type="text" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
                  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Dirección de Correo</label>
                              <input type="email" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Rut</label>
                              <input type="text" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                     
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Edad</label>
                              <input type="number" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                              <input type="tel" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Iglesia</label>
                              <input type="text" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Acreditado</label>
                              <input type="text" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Grado</label>
                              <select className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm">
                                  <option>Presbítero</option>
                                  <option>Diácono</option>
                                  <option>Probando</option>
                              </select>
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Zona</label>
                              <input type="text" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">País</label>
                              <input type="text" className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm" />
                          </div>
  
                          <div>
                              <label className="block text-sm font-medium text-gray-700">Perfil</label>
                              <select className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 sm:text-sm">
                                  <option>Admin</option>
                                  <option>Custodia</option>
                                  <option>Despensa</option>
                                  <option>Visita</option>
                              </select>
                          </div>
                      </div>
  
                  </div>
  
                  {/* Botones de Acción */}
                  <div className="mt-8 flex items-center justify-end space-x-4">
                      <button  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                               type="button"
                               onClick={handleCancel}>
                          Cancelar
                      </button>
                      <button type="submit" className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          Guardar
                      </button>
                      <button type="button" className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                          Eliminar
                      </button>
                  </div>
              </form>
          </div>
      );
  };
  
  export default EditUserRol;
  