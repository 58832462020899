import React from 'react';

const DetailUser: React.FC = () => {
  const user = {
    email: 'camila@castros.cl',
    nombre: 'Camila',
    apellidoPaterno: 'Castro',
    apellidoMaterno: 'Vergara',
    rut: '99.9999.999-9',
    perfil: 'Administrador',
    hospedaje: 'Avenida Avenida',
    teléfono: '+56976424587',
    mesa: '5',
    condiciones_medicas: ['Hipertensión', 'Diabetes', 'Celiaco', 'Otra'],
    vehiculo: 'Sí',
    asiste_esposa: 'Sí',
    auto: 'Patente',
    custodia: 'XXXXXXXX',
    nombre_esposa: 'Maria Filomena',
    rut_esposa: '1.111.111-K',
    edad_esposa: 45,
    enfermedad_base_esposa: ['Hipertensión', 'Diabetes', 'Celiaca', 'Otra'],
    fotografia_esposa: 'uploads/visitis/photos',
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-2xl w-full bg-white p-6 rounded-lg shadow-md">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-semibold leading-7 text-gray-900">Información del Usuario</h3>
          <p className="mt-1 text-sm text-gray-500">Detalles personales y de cuenta.</p>
        </div>

        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* Información básica del usuario */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Nombre Completo</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {user.nombre} {user.apellidoPaterno} {user.apellidoMaterno}
              </dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Correo Electrónico</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.email}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">RUT</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.rut}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Perfil</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.perfil}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Teléfono</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.teléfono}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Hospedaje Asignado</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.hospedaje}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Mesa Asignada</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.mesa}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Vehículo Propio</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.vehiculo}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Auto Asignado</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.auto}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Custodia</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.custodia}</dd>
            </div>

            {/* Condiciones Médicas */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Condiciones Médicas</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {user.condiciones_medicas.join(', ')}
              </dd>
            </div>

            {/* Información de la Esposa */}
            <div className="py-4">
              <h4 className="text-lg font-semibold text-gray-900">Información de Esposa</h4>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Nombre</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.nombre_esposa}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">RUT</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.rut_esposa}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Edad</dt>
              <dd className="text-sm text-gray-700 col-span-2">{user.edad_esposa}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Enfermedad Base</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {user.enfermedad_base_esposa.join(', ')}
              </dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Fotografía</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                <img src={user.fotografia_esposa} alt="Fotografía Esposa" className="w-20 h-20 rounded-md" />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default DetailUser;
