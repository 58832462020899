// src/pages/VehicleParkingPage.tsx

import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { PlusCircleIcon, ClipboardIcon, EyeIcon } from '@heroicons/react/24/outline';

const vehicleOptions = [
  { name: 'Ver Automóviles', href: 'list', icon: EyeIcon },
  { name: 'Agregar Automóvil', href: 'add_vehicle', icon: PlusCircleIcon },
  { name: 'Asignar Automóvil a Usuario', href: 'assign_vehicle', icon: ClipboardIcon },
];

const VehicleParkingPage: React.FC = () => {
  return (
    <div className="p-11 bg-white-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Vehículos</h1>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {vehicleOptions.map((option) => (
            <Link
              key={option.name}
              to={option.href}
              className="flex items-center gap-x-2.5 p-4 px-6 text-sm font-semibold text-gray-900 hover:bg-gray-100 sm:justify-center"
            >
              <option.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
              {option.name}
            </Link>
          ))}
        </div>
      </div>
      <div className="mt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default VehicleParkingPage;
