import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../../contexts/GlobalStateContext';
import QrReader from '../QrReader';

const ReceiveLuggage: React.FC = () => {
  const { custodySpaces, assignSpace } = useAppContext();
  const [scannedQrData, setScannedQrData] = useState<string | null>(null);
  const [selectedSpaceId, setSelectedSpaceId] = useState<number | null>(null);
  const navigate = useNavigate();

  const handleAssignSpace = () => {
    if (selectedSpaceId) {
      assignSpace(selectedSpaceId); // Actualiza a "ocupado"
      setSelectedSpaceId(null); // Resetea el espacio seleccionado
      alert('Espacio asignado correctamente');
    } else {
      alert('Seleccione un espacio para asignar');
    }
  };

  /*console.log("qrreader type:", type);
  console.log("qrreader spaceId:", spaceId);*/

  return (
    <div className="flex flex-col items-center justify-center min-h-screen p-4 bg-gray-50 relative">
      {/* Header with Back Button */}
      <div className="flex items-center justify-center w-full mb-8">
        <button
          onClick={() => navigate('/Custody/create_section')}
          className="absolute top-0 left-4 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-500"
        >
          Volver
        </button>
        <h2 className="text-xl font-semibold text-center">Recepción de Equipaje</h2>
      </div>

      {/* QR Reader */}
      <div className="w-full max-w-sm">
      <QrReader onScan={(data) => setScannedQrData(data)} />
      </div>
    </div>
  );
};

export default ReceiveLuggage;
