// src/hooks/useUserDetails.ts
import { useState, useEffect } from 'react';

interface UserDetails {
  user_id: number;
  correo: string;
  rut: string;
  nombre: string;
  apellidop: string;
  apellidom: string;
  edad: number;
  telefono: string;
  iglesia: string;
  acreditado: boolean;
  grado: string;
  zona: string;
  pais: string;
  perfil: string;
  hipertension: boolean;
  diabetes: boolean;
  celiaco: boolean;
  vehiculoPropio: boolean;
  esposa: boolean;
  rut_esposa?: string;
  nombre_esposa?: string;
  apellidop_esposa?: string;
  apellidom_esposa?: string;
  edad_esposa?: number;
  hipertension_esposa?: boolean;
  diabetes_esposa?: boolean;
  celiaco_esposa?: boolean;
  vehicles: any[];
  custodia: any[];
}

const useUserDetails = (userId: number | null) => {
  const [userDetails, setUserDetails] = useState<UserDetails | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://agy-estudio-biblico-production.up.railway.app/users/${userId}/details`, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        if (!response.ok) {
          throw new Error('Error al obtener los detalles del usuario');
        }

        const data = await response.json();
        setUserDetails(data);
      } catch (error) {
        setError((error as Error).message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [userId]);

  return { userDetails, loading, error };
};

export default useUserDetails;
