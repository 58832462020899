//QrReader.tsx
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./QrStyles.css";
import { Scanner, IDetectedBarcode, IScannerClassNames } from "@yudiel/react-qr-scanner";
import QrFrame from "../assets/qr-frame.svg";
import { registerEntry } from "../../src/apis/users/QrAPI";
import { updateCustody, releaseCustody } from "../../src/apis/custody/useCustody";
import { useAppContext } from "../../src/contexts/GlobalStateContext";

interface QrReaderProps {
  onScan: (data: string) => void;
}

const QrReader: React.FC<QrReaderProps> = ({ onScan }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { type, spaceId } = location.state || { type: "Unknown", spaceId: null };
  const { assignSpace, freeSpace } = useAppContext(); // Obtener la función assignSpace del contexto

  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const showAlert = (message: string) => {
    setAlertMessage(message);
  };

  const closeAlert = () => {
    setAlertMessage(null);
    if (type === "Asignar" || type === "Entregar") {
      navigate("/Custody/create_section");
    } else if (type === "Entrada" || type === "Salida") {
      navigate("/ScanQrModal");
    } else {
      navigate("/ScanQrModal");
    }
  };

  const handleScan = async (data: string) => {
    if (isProcessing) return;
    setIsProcessing(true);
  
    try {
      const parsedData = JSON.parse(data);
      const userId = parsedData.data;
  
      if (isNaN(userId)) {
        showAlert("El código QR no contiene un ID de usuario válido.");
        setIsProcessing(false);
        return;
      }
  
      if (type === "Asignar" && spaceId) {
        // Logic for assigning space
        const response = await updateCustody({
          spaceId: spaceId,
          idUsuario: userId,
        });
        if (response) {
          showAlert("Espacio asignado correctamente.");
          assignSpace(spaceId); // Actualiza el estado global aquí
        } else {
          showAlert("Error al asignar el espacio.");
        }
      } else if (type === "Entregar" && spaceId) {
        // Nueva lógica utilizando releaseSpace
        const response = await releaseCustody(spaceId);
        if (response) {
            showAlert("Espacio liberado correctamente.");
            freeSpace(spaceId); // Actualiza el estado global
        } else {
            showAlert("Error al liberar el espacio.");
        }
      } else if (type === "Entrada" || type === "Salida") {
        // Logic for registering entries or exits
        const response = await registerEntry(userId.toString(), { tipo_ingreso: type });
        if (response.ok) showAlert(`Registro de ${type.toLowerCase()} exitoso.`);
        else showAlert("Error al registrar. Inténtalo nuevamente.");
      } else {
        showAlert("Acción desconocida.");
      }
    } catch (error: any) {
      console.error("Error al procesar el escaneo:", error.message);
      showAlert(`Error al procesar el escaneo: ${error.message}`);
    } finally {
      setIsProcessing(false);
    }
  };
  
  return (
    <div className="qr-reader">
      <p className="qr-type">Tipo de Escaneo: {type}</p>
      <p className="qr-type">Id: {spaceId}</p>

      <Scanner
        onScan={(detectedCodes: IDetectedBarcode[]) => {
          if (detectedCodes.length > 0) {
            handleScan(detectedCodes[0].rawValue);
          }
        }}
        constraints={{ facingMode: "environment" }}
        classNames={{
          container: "qr-scanner-container",
          video: "qr-scanner-video",
          canvas: "qr-scanner-canvas",
        } as IScannerClassNames}
      />

      <div className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>

      {alertMessage && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black opacity-50 z-40"></div>
          <div className="relative bg-gray-900 p-6 rounded-md shadow-md z-50 max-w-sm mx-auto text-white">
            <h3 className="text-lg font-semibold mb-4">Alerta</h3>
            <p className="mb-6">{alertMessage}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={closeAlert}
                className="px-4 py-2 bg-gray-800 text-white font-bold rounded-md hover:bg-gray-700"
              >
                Aceptar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default QrReader;
