// src/apis/custody/createCustody.tsx
    interface CustodySpace {
        id?: number;
        ubicacion: string;
        capacidad: 'Maleta' | 'Bolso de mano';
        ocupado: boolean;
    }

    export const createCustody = async (custodySpace: CustodySpace) => {
    try {
        const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/custodia', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(custodySpace),
        });

        if (!response.ok) {
        throw new Error(`Error creating custody space: ${response.statusText}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
    };

export default createCustody;