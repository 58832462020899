// src/hooks/useCreateUser.ts
import { useState } from 'react';

interface CreateUserResponse {
  success: boolean;
  message: string;
}

interface CreateUserProps {
  correo: string;
  password: string;
  rut: string;
  nombre: string;
  apellidop: string;
  apellidom: string;
  edad: number;
  telefono: string;
  iglesia: string;
  acreditado: boolean;
  grado: string;
  zona: string;
  pais: string;
  perfil: string;
  hipertension: boolean;
  diabetes: boolean;
  celiaco: boolean;
  vehiculo: boolean;
  esposa: boolean;
  rut_esposa?: string;
  nombre_esposa?: string;
  apellidop_esposa?: string;
  apellidom_esposa?: string;
  edad_esposa?: number;
  hipertension_esposa?: boolean;
  diabetes_esposa?: boolean;
  celiaco_esposa?: boolean;
}

const useCreateUser = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [response, setResponse] = useState<CreateUserResponse | null>(null);

  const createUser = async (userData: CreateUserProps) => {
    setLoading(true);
    setError(null);

    try {
      const res = await fetch('https://agy-estudio-biblico-production.up.railway.app/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      if (!res.ok) {
        throw new Error('Error al crear el usuario');
      }

      const data: CreateUserResponse = await res.json();
      setResponse(data);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { createUser, loading, error, response };
};

export default useCreateUser;
