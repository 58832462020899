// src/components/users/EditUser.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { validateRUT } from '../../utils/validateRUT';
import useUsers from '../../../apis/users/useUsers';

const EditUser: React.FC = () => {
  const { users, editUser, loading, error } = useUsers();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id || '0', 10);
  const user = users.find((user) => user.user_id === userId);

  const [formData, setFormData] = useState({
    user_id: userId,
    correo: '',
    password: '',
    rut: '',
    nombre: '',
    apellidop: '',
    apellidom: '',
    edad: 0,
    telefono: '',
    iglesia: '',
    acreditado: false,
    grado: '',
    zona: '',
    pais: '',
    perfil: 'Visitante',
    hipertension: false,
    diabetes: false,
    celiaco: false,
    vehiculo: false,
    esposa: false,
    rut_esposa: '',
    nombre_esposa: '',
    apellidop_esposa: '',
    apellidom_esposa: '',
    edad_esposa: 0,
    hipertension_esposa: false,
    diabetes_esposa: false,
    celiaco_esposa: false,
  });

  // Estados para manejar errores de RUT
  const [rutError, setRutError] = useState<string>('');
  const [rutEsposaError, setRutEsposaError] = useState<string>('');

  useEffect(() => {
    if (user) {
      setFormData({
        ...formData,
        user_id: user.user_id,
        correo: user.correo,
        password: user.password || '',
        rut: user.rut,
        nombre: user.nombre,
        apellidop: user.apellidop || '',
        apellidom: user.apellidom || '',
        edad: user.edad || 0,
        telefono: user.telefono || '',
        iglesia: user.iglesia || '',
        acreditado: user.acreditado,
        grado: user.grado || '',
        zona: user.zona || '',
        pais: user.pais || '',
        perfil: user.perfil || 'Visitante',
        hipertension: user.hipertension,
        diabetes: user.diabetes,
        celiaco: user.celiaco,
        vehiculo: user.vehiculo,
        esposa: user.esposa,
        rut_esposa: user.rut_esposa || '',
        nombre_esposa: user.nombre_esposa || '',
        apellidop_esposa: user.apellidop_esposa || '',
        apellidom_esposa: user.apellidom_esposa || '',
        edad_esposa: user.edad_esposa || 0,
        hipertension_esposa: user.hipertension_esposa,
        diabetes_esposa: user.diabetes_esposa,
        celiaco_esposa: user.celiaco_esposa,
      });

      // Validar el RUT inicial
      if (!validateRUT(user.rut)) {
        setRutError('RUT inválido');
      } else {
        setRutError('');
      }

      // Validar el RUT de la esposa si aplica
      if (user.esposa) {
        if (!validateRUT(user.rut_esposa || '')) {
          setRutEsposaError('RUT de la esposa inválido');
        } else {
          setRutEsposaError('');
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    if (e.target instanceof HTMLInputElement && e.target.type === 'checkbox') {
      setFormData({ ...formData, [name]: e.target.checked });

      // Si se desmarca 'esposa', limpiar los campos relacionados
      if (name === 'esposa' && !e.target.checked) {
        setFormData({
          ...formData,
          esposa: false,
          rut_esposa: '',
          nombre_esposa: '',
          apellidop_esposa: '',
          apellidom_esposa: '',
          edad_esposa: 0,
          hipertension_esposa: false,
          diabetes_esposa: false,
          celiaco_esposa: false,
        });
        setRutEsposaError('');
      }
    } else {
      setFormData({ ...formData, [name]: value });

      // Validación específica para el campo RUT
      if (name === 'rut') {
        if (value === '') {
          setRutError('El campo RUT es obligatorio');
        } else if (!validateRUT(value)) {
          setRutError('RUT inválido');
        } else {
          setRutError('');
        }
      }

      // Validar el RUT de la esposa si el campo está activo
      if (name === 'rut_esposa') {
        if (formData.esposa) {
          if (value === '') {
            setRutEsposaError('El campo RUT de la esposa es obligatorio');
          } else if (!validateRUT(value)) {
            setRutEsposaError('RUT de la esposa inválido');
          } else {
            setRutEsposaError('');
          }
        }
      }
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validar RUT antes de enviar
    if (!validateRUT(formData.rut)) {
      setRutError('RUT inválido');
      return;
    }

    // Si la esposa está presente, validar también su RUT
    if (formData.esposa && !validateRUT(formData.rut_esposa || '')) {
      setRutEsposaError('RUT de la esposa inválido');
      return;
    }

    await editUser(userId, formData);
    navigate('/Users/list');
  };

  const handleCancel = () => {
    navigate('/Users/list'); // Redirige a la lista de usuarios al cancelar
  };

  if (!user) {
    return <div className="flex justify-center items-center min-h-screen bg-gray-100"><p className="text-red-500">Usuario no encontrado</p></div>;
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <form className="w-full max-w-md p-6 bg-white rounded shadow-md" onSubmit={handleSubmit}>
        <h2 className="text-2xl font-bold mb-4 text-center">Editar Usuario</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}

        {/* Datos del Usuario */}

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Correo:
          <input
            type="email"
            name="correo"
            value={formData.correo}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md ${error ? 'border-red-500' : 'border-gray-300'}`}
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Contraseña:
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          RUT:
          <input
            type="text"
            name="rut"
            value={formData.rut}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md ${
              rutError ? 'border-red-500' : 'border-gray-600'
            }`}
            placeholder="Ingresa el RUT sin puntos ni guión"
            required
          />
          {rutError && <p className="text-red-500 text-xs mt-1">{rutError}</p>}
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Nombre:
          <input
            type="text"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
            required
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Apellido Paterno:
          <input
            type="text"
            name="apellidop"
            value={formData.apellidop}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md`}
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Apellido Materno:
          <input
            type="text"
            name="apellidom"
            value={formData.apellidom}
            onChange={handleChange}
            className={`mt-1 block w-full p-2 border rounded-md`}
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Edad:
          <input
            type="number"
            name="edad"
            value={formData.edad}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Teléfono:
          <input
            type="text"
            name="telefono"
            value={formData.telefono}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Iglesia:
          <input
            type="text"
            name="iglesia"
            value={formData.iglesia}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Grado:
          <input
            type="text"
            name="grado"
            value={formData.grado}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Zona:
          <input
            type="text"
            name="zona"
            value={formData.zona}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          País:
          <input
            type="text"
            name="pais"
            value={formData.pais}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          />
        </label>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Perfil:
          <select
            name="perfil"
            value={formData.perfil}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border rounded-md"
          >
            <option value="Administrador">Administrador</option>
            <option value="Visitante">Visitante</option>
            <option value="Encargado de Despensa">Encargado de Despensa</option>
            <option value="Encargado de Custodia">Encargado de Custodia</option>
            <option value="Encargado de Vehículos">Encargado de Vehículos</option>
          </select>
        </label>

        {/* Condiciones de Salud */}
        <div className="grid grid-cols-2 gap-4 mt-4">
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="hipertension"
              checked={formData.hipertension}
              onChange={handleChange}
              className="mr-2"
            />
            Hipertensión
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="diabetes"
              checked={formData.diabetes}
              onChange={handleChange}
              className="mr-2"
            />
            Diabetes
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="celiaco"
              checked={formData.celiaco}
              onChange={handleChange}
              className="mr-2"
            />
            Celíaco
          </label>
          <label className="flex items-center text-sm font-semibold text-gray-600">
            <input
              type="checkbox"
              name="vehiculo"
              checked={formData.vehiculo}
              onChange={handleChange}
              className="mr-2"
            />
            Vehículo Propio
          </label>
        </div>

        <label className="block mb-2 text-sm font-semibold text-gray-600">
          Acreditado:
          <input
            type="checkbox"
            name="acreditado"
            checked={formData.acreditado}
            onChange={handleChange}
            className="mr-2"
          />
        </label>

        {/* Sección de la Esposa */}
        <label className="block mb-2 text-sm font-semibold text-gray-600 flex items-center">
          <input
            type="checkbox"
            name="esposa"
            checked={formData.esposa}
            onChange={handleChange}
            className="mr-2"
          />
          Asiste Esposa
        </label>

        {formData.esposa && (
          <>
            <div className="mt-4 text-lg font-semibold text-gray-700">Datos de la Esposa</div>

            <label className="block mb-2 text-sm font-semibold text-gray-600">
              RUT:
              <input
                type="text"
                name="rut_esposa"
                value={formData.rut_esposa}
                onChange={handleChange}
                className={`mt-1 block w-full p-2 border rounded-md ${
                  rutEsposaError ? 'border-red-500' : 'border-gray-600'
                }`}
                placeholder="Ingresa el RUT sin puntos ni guión"
                required
              />
              {rutEsposaError && <p className="text-red-500 text-xs mt-1">{rutEsposaError}</p>}
            </label>

            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Nombre:
              <input
                type="text"
                name="nombre_esposa"
                value={formData.nombre_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
              />
            </label>

            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Apellido Paterno:
              <input
                type="text"
                name="apellidop_esposa"
                value={formData.apellidop_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
              />
            </label>

            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Apellido Materno:
              <input
                type="text"
                name="apellidom_esposa"
                value={formData.apellidom_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
              />
            </label>

            <label className="block mb-2 text-sm font-semibold text-gray-600">
              Edad:
              <input
                type="number"
                name="edad_esposa"
                value={formData.edad_esposa}
                onChange={handleChange}
                className="mt-1 block w-full p-2 border rounded-md"
              />
            </label>

            <div className="grid grid-cols-2 gap-4 mt-4">
              <label className="flex items-center text-sm font-semibold text-gray-600">
                <input
                  type="checkbox"
                  name="hipertension_esposa"
                  checked={formData.hipertension_esposa}
                  onChange={handleChange}
                  className="mr-2"
                />
                Hipertensión
              </label>
              <label className="flex items-center text-sm font-semibold text-gray-600">
                <input
                  type="checkbox"
                  name="diabetes_esposa"
                  checked={formData.diabetes_esposa}
                  onChange={handleChange}
                  className="mr-2"
                />
                Diabetes
              </label>
              <label className="flex items-center text-sm font-semibold text-gray-600">
                <input
                  type="checkbox"
                  name="celiaco_esposa"
                  checked={formData.celiaco_esposa}
                  onChange={handleChange}
                  className="mr-2"
                />
                Celíaca
              </label>
            </div>
          </>
        )}

        {/* Botón de Enviar */}
        <button
          type="submit"
          className="w-full py-2 mt-4 bg-indigo-600 text-white font-semibold rounded-md hover:bg-indigo-500"
          disabled={loading}
        >
          {loading ? 'Guardando...' : 'Guardar Cambios'}
        </button>
        {/* Botón de Cancelar */}
        <button
          type="button"
          onClick={handleCancel}
          className="w-full py-2 mt-2 bg-gray-500 text-white font-semibold rounded-md hover:bg-gray-400"
        >
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default EditUser;
