import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../contexts/GlobalStateContext';

const Login = () => {
  const { login } = useAppContext(); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    
    const userResponse = await login(email, password); // Recibe la respuesta de usuario

    if (userResponse) {
      const perfil = userResponse.user.perfil; // Obtiene el perfil del usuario
      const defaultRoute = //ruta a la q llega al loggearse
        perfil === 'Administrador' ? '/UserProfile' : 
        perfil === 'Encargado de Despensa' ? '/Inventary/inventary_list' : 
        perfil === 'Visitante' ? '/UserProfile' : 
        perfil === 'Encargado de Custodia' ? '/Custody/create_section' :
        perfil === 'Encargado de Vehículos' ? '/Vehicles/list' :
        '/UserProfile';
      
      navigate(defaultRoute);
    } else {
      setError('Credenciales incorrectas, por favor intenta de nuevo.');
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded-lg shadow-lg ring-1 ring-white/10">
        <h2 className="text-2xl font-bold text-center text-white">Iniciar Sesión</h2>
        {error && <p className="text-red-500 text-center">{error}</p>}

        <form className="space-y-6" onSubmit={handleLogin}>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-400">Correo electrónico</label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-1 p-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <div>
            <label htmlFor="password" className="block text-sm font-medium text-gray-400">Contraseña</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full mt-1 p-2 border border-gray-600 rounded-md shadow-sm bg-gray-700 text-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
              required
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 text-white bg-indigo-600 rounded-md hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Iniciar Sesión
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
