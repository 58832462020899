import { BriefcaseIcon, BuildingOfficeIcon, CameraIcon, ChartBarIcon, ClipboardDocumentIcon, MapIcon, UserIcon, Bars3Icon } from '@heroicons/react/24/solid';
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useAppContext } from '../contexts/GlobalStateContext';

const userSesion = [{ nombre: 'Administrador', apellido: '' }];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const MainLayout: React.FC = () => {

  const { user } = useAppContext();
// Opciones de navegación basadas en el perfil del usuario
const navigation =  user?.user.perfil === 'Administrador'
  ? [
      { name: 'Perfil', href: '/UserProfile', icon: ClipboardDocumentIcon },
      { name: 'Escanear Código QR', href: '/ScanQr', icon: CameraIcon },
      { name: 'Alojamientos', href: '/Alojamientos', icon: BuildingOfficeIcon },
      { name: 'Despensa', href: '/Inventary', icon: ClipboardDocumentIcon },
      { name: 'Custodia', href: '/Custody', icon: BriefcaseIcon },
      { name: 'Movilización', href: '/Vehicles', icon: MapIcon },
      // Añade más opciones para Administrador
    ]
  : user?.user.perfil === 'Visitante'
  ? [
      { name: 'Perfil', href: '/UserProfile', icon: ClipboardDocumentIcon },
      // Añade más opciones para Visitante
    ]
  : [
      { name: 'Inicio', href: '/home', icon: ClipboardDocumentIcon },
      // Opciones para otros perfiles
    ];
 
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <div className="flex min-h-screen">
      {/* Sidebar sólido para todas las resoluciones */}
      <div className={`${isSidebarOpen ? 'block' : 'hidden'} fixed inset-0 z-30 bg-gray-900 lg:relative lg:block lg:w-72`}>
        <div className="flex flex-col h-full py-6 px-4 lg:px-6">
          {/* Botón de cierre para móvil */}
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="self-end text-gray-400 lg:hidden"
          >
            <span className="sr-only">Cerrar sidebar</span>
            <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          {/* Información de usuario */}
          <div className="flex items-center gap-4 mb-6">
            <Link to="/UserProfile">
              <UserIcon className="h-10 w-10 text-white cursor-pointer" />
            </Link>
            <div className="text-white font-semibold">
              {userSesion.map((user) => (
                <div key={user.nombre}>{user.nombre} {user.apellido}</div>
              ))}
            </div>
          </div>

          {/* Navegación */}
          <nav className="flex-grow">
            <ul className="space-y-2">
              {navigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    onClick={() => setIsSidebarOpen(false)}  // Cierra el menú al hacer clic
                    className={classNames(
                      location.pathname === item.href
                        ? 'bg-gray-800 text-white'
                        : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                      'group flex items-center gap-4 px-4 py-3 rounded-md text-sm font-medium'
                    )}
                  >
                    <item.icon className="h-5 w-5" />
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </div>

      {/* Botón hamburguesa para dispositivos pequeños */}
      <button
        onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        className="lg:hidden p-4 text-gray-600 fixed top-4 left-4 z-40"
      >
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        <span className="sr-only">Abrir sidebar</span>
      </button>

      {/* Contenido principal */}
      <div className="flex-grow bg-gray-100 p-6 lg:pl-72">
        <Outlet />  {/* Renderiza el contenido de la página seleccionada */}
      </div>
    </div>
  );
};

export default MainLayout;
