// src/App.tsx
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom'; // Usa BrowserRouter
import MainRoutes from './routes/MainRoutes';
import { AppProvider } from './contexts/GlobalStateContext';

const App: React.FC = () => {
  return (
    <Router> {/* Router debe envolver a todo el contenido */}
      <AppProvider> {/* AppProvider puede estar dentro del Router */}
        <MainRoutes />
      </AppProvider>
    </Router>
  );
};

export default App;
