// src/components/utils/QrCodeGenerator.tsx

import React from 'react';
import { QRCodeCanvas } from 'qrcode.react'; // Importar QRCodeCanvas en lugar de QRCode

interface QrCodeGeneratorProps {
  value: string; // Recibe los datos a codificar en el QR
}

const QrCodeGenerator: React.FC<QrCodeGeneratorProps> = ({ value }) => {
  // Serializar los datos del usuario en un formato adecuado para mostrar en el QR
  const qrData = JSON.stringify({ data: value });

  return (
    <div className="flex flex-col items-center">
      <QRCodeCanvas
        value={qrData}
        size={350} // Tamaño más grande del QR
        level="L"
        includeMargin={true}
        className="rounded-lg shadow-md"
      />
      <p className="text-sm text-gray-500 mt-2">Escanea el código para ver los detalles del usuario.</p>
    </div>
  );
};

export default QrCodeGenerator;
