import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import MainLayout from '../pages/MainLayout';
import Login from '../pages/Login';
import QrReader from '../components/QrReader';
import UsersPage from '../pages/UserPage';
import CustodyPage from '../pages/CustodyPage';
import InventaryPage from '../pages/InventaryPage';
import VehicleParkingPage from '../pages/VehicleParkingPage';
import { AddVehicle, AssignVehicle, VehicleList } from '../components/vehicles';
import { AddUser, AddUserRol, DetailUser, DetailUserRol, EditUser, EditUserRol, UserProfile, ViewUsers, ViewUsersRoles } from '../components/users';
import { CustodySections, DeliverLuggage, ReceiveLuggage } from '../components/custody';
import { InventaryList, EditProduct, DeliveryListProduct, DeliveryProduct, AlertInventary } from '../components/inventary';




const MainRoutes = () => {
  const handleQrScan = (data: string) => {
    console.log('QR escaneado:', data);
  };

  return (
    <Routes>
      {/* Ruta de login sin layout */}
      <Route path="/" element={<Login />} />

      {/* Rutas protegidas con layout principal */}
      <Route element={<ProtectedRoute />}>
        <Route element={<MainLayout />}>
          <Route path="/UserProfile" element={<UserProfile />} />

          {/* Módulo de Usuarios */}
          <Route path="Users" element={<UsersPage />}>
            <Route path="add" element={<AddUser />} />
            <Route path="add_rol" element={<AddUserRol />} />
            <Route path="list" element={<ViewUsers />} />
            <Route path="list_roles" element={<ViewUsersRoles />} />
          </Route>
          <Route path="edit_user/:id" element={<EditUser />} />
          <Route path="detail_user/:id" element={<DetailUser />} />
          <Route path="edit_user_rol/:id" element={<EditUserRol />} />
          <Route path="detail_user_rol/:id" element={<DetailUserRol />} />

          {/* Escáner QR */}
          <Route path="/ScanQr" element={<QrReader onScan={handleQrScan} />} />

          {/* Módulo de Despensa */}
          <Route path="/Inventary" element={<InventaryPage />}>
            <Route path="inventary_list" element={<InventaryList />} />
            <Route path="edit_product/:id" element={<EditProduct />} />
            <Route path="delivery_list" element={<DeliveryListProduct />} />
            <Route path="delivery_product" element={<DeliveryProduct />} />
            <Route path="alert" element={<AlertInventary />} />
          </Route>

          {/* Módulo de Custodia */}
          <Route path="/Custody" element={<CustodyPage />}>
            <Route path="create_section" element={<CustodySections />} />
            <Route path="receive_luggage" element={<ReceiveLuggage />} />
            <Route path="deliver_luggage" element={<DeliverLuggage />} />
          </Route>

          {/* Módulo de Vehículos */}
          <Route path="/Vehicles" element={<VehicleParkingPage />}>
            <Route path="list" element={<VehicleList />} />
            <Route path="add_vehicle" element={<AddVehicle />} />
            <Route path="assign_vehicle" element={<AssignVehicle />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRoutes;
