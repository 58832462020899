// src/components/vehicles/AssignVehicle.tsx

import React, { useState } from 'react';

const users = [
  { id: 1, nombre: 'Juan Perez' },
  { id: 2, nombre: 'Maria Lopez' },
];

const vehicles = [
  { id: 1, marca: 'Toyota', modelo: 'Corolla', patente: 'AB-1234' },
  { id: 2, marca: 'Honda', modelo: 'Civic', patente: 'XY-5678' },
];

const AssignVehicle: React.FC = () => {
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedVehicle, setSelectedVehicle] = useState('');

  const handleAssign = () => {
    console.log(`Asignado vehículo ${selectedVehicle} a usuario ${selectedUser}`);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Asignar Automóvil a Usuario</h2>
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">Seleccionar Usuario</label>
          <select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecciona un usuario</option>
            {users.map((user) => (
              <option key={user.id} value={user.id}>
                {user.nombre}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Seleccionar Automóvil</label>
          <select
            value={selectedVehicle}
            onChange={(e) => setSelectedVehicle(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          >
            <option value="">Selecciona un automóvil</option>
            {vehicles.map((vehicle) => (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.marca} {vehicle.modelo} ({vehicle.patente})
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={handleAssign}
          className="mt-4 inline-flex items-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500"
        >
          Asignar Automóvil
        </button>
      </div>
    </div>
  );
};

export default AssignVehicle;
