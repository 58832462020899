// src/components/custody/ReceiveLuggage.tsx
import React, { useState } from 'react';
import { useAppContext } from '../../contexts/GlobalStateContext';
import QrReader from '../QrReader';

const ReceiveLuggage: React.FC = () => {
  const { custodySpaces, assignSpace } = useAppContext();
  const [scannedQrData, setScannedQrData] = useState<string | null>(null);
  const [selectedSpaceId, setSelectedSpaceId] = useState<number | null>(null);

  const handleAssignSpace = () => {
    if (selectedSpaceId) {
      assignSpace(selectedSpaceId); // Actualiza a "ocupado"
      setSelectedSpaceId(null); // Resetea el espacio seleccionado
      alert('Espacio asignado correctamente');
    } else {
      alert('Seleccione un espacio para asignar');
    }
  };

  return (
    <div>
      <h2 className="text-xl font-semibold">Recepción de Equipaje</h2>
      <div className="my-4">
        <QrReader onScan={(data) => setScannedQrData(data)} />
      </div>
      
      <select
        value={selectedSpaceId ?? ''}
        onChange={(e) => setSelectedSpaceId(Number(e.target.value))}
        className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="">Seleccione un espacio disponible</option>
        {custodySpaces
          .filter(space => !space.occupied) // Mostrar solo los disponibles
          .map(space => (
            <option key={space.id} value={space.id}>
              {space.location} - {space.capacity}
            </option>
          ))}
      </select>
      
      <button
        onClick={handleAssignSpace}
        className="mt-4 inline-flex items-center px-4 py-2 bg-indigo-600 text-white rounded-md"
      >
        Asignar Espacio
      </button>
    </div>
  );
};

export default ReceiveLuggage;
