// src/hooks/useAddUser.ts
import { useState } from 'react';

interface AddUserData {
  nombre: string;
  telefono: string;
  acreditado: boolean;
  grado: string;
  zona: string;
  pais: string;
  perfil: string;
  hipertension: boolean;
  diabetes: boolean;
  celiaco: boolean;
  vehiculoPropio: boolean;
  esposa: boolean;
  rut_esposa?: string;
  nombre_esposa?: string;
  apellidop_esposa?: string;
  apellidom_esposa?: string;
  edad_esposa?: number;
  hipertension_esposa?: boolean;
  diabetes_esposa?: boolean;
  celiaco_esposa?: boolean;
}

const useAddUser = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const addUser = async (userData: AddUserData): Promise<boolean> => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch('https://agy-estudio-biblico-production.up.railway.app/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      console.log(userData);

      if (!response.ok) {
        throw new Error('No se pudo agregar el usuario');
      }

      return true; // Indica éxito
    } catch (error) {
      setError((error as Error).message);
      return false; // Indica error
    } finally {
      setLoading(false);
    }
  };

  return { addUser, loading, error };
};

export default useAddUser;
