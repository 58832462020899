import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCreateUser from '../../../apis/createuser';

const AddUser = () => {
    const { createUser, loading, error, response } = useCreateUser();
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        correo: '',
        password: '',
        rut: '',
        nombre: '',
        apellidop: '',
        apellidom: '',
        edad: 0,
        telefono: '',
        iglesia: '',
        acreditado: false,
        grado: '',
        zona: '',
        pais: '',
        perfil: '',
        hipertension: false,
        diabetes: false,
        celiaco: false,
        vehiculo: false,
        esposa: false,
        rut_esposa: '',
        nombre_esposa: '',
        apellidop_esposa: '',
        apellidom_esposa: '',
        edad_esposa: 0,
        hipertension_esposa: false,
        diabetes_esposa: false,
        celiaco_esposa: false,
      });


    const handleCancel = () => {
        navigate('/Users');
    };

    

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // Lógica para agregar usuario
        navigate('/Users');
    };

    return (
        <div className="p-8 bg-gray-100 min-h-screen">
            <h1 className="text-3xl font-bold text-gray-800 text-center">Agregar Usuario</h1>

            <form onSubmit={handleSubmit} className="mt-8 space-y-12">
                {/* Información Personal */}
                <div className="border-b border-gray-300 pb-8">
                    <h2 className="text-lg font-semibold text-gray-700">Información Personal</h2>
                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Fotografía</label>
                            <input type="file" className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:bg-indigo-600 file:text-white hover:file:bg-indigo-700" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Dirección de Correo</label>
                            <input type="email" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">RUT</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Nombre</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Edad</label>
                            <input type="number" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Teléfono</label>
                            <input type="tel" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Iglesia</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Acreditado</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Grado</label>
                            <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                <option>Presbítero</option>
                                <option>Diácono</option>
                                <option>Probando</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Zona</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">País</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Perfil</label>
                            <select className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                <option>Admin</option>
                                <option>Custodia</option>
                                <option>Despensa</option>
                                <option>Visita</option>
                            </select>
                        </div>
                    </div>

                    {/* Checkboxes de condiciones */}
                    <fieldset className="mt-8">
                        <legend className="text-lg font-semibold text-gray-700">Condiciones Médicas</legend>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mt-4">
                            {['Hipertensión', 'Diabetes', 'Celiaco', 'Vehículo Propio', 'Asiste Esposa'].map((condition) => (
                                <label key={condition} className="flex items-center">
                                    <input type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                    <span className="ml-2 text-gray-700">{condition}</span>
                                </label>
                            ))}
                        </div>
                    </fieldset>
                </div>

                {/* Información Esposa */}
                <div className="border-b border-gray-300 pb-8">
                    <h2 className="text-lg font-semibold text-gray-700">Información de Esposa</h2>

                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 gap-6">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Nombre</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">RUT</label>
                            <input type="text" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Edad</label>
                            <input type="number" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Enfermedad Base</label>
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-2">
                                {['Hipertensión', 'Diabetes', 'Celiaca', 'Otra'].map((condition) => (
                                    <label key={condition} className="flex items-center">
                                        <input type="checkbox" className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500" />
                                        <span className="ml-2 text-gray-700">{condition}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">Fotografía</label>
                            <input type="file" className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:bg-indigo-600 file:text-white hover:file:bg-indigo-700" />
                        </div>
                    </div>
                </div>

                {/* Botones de Acción */}
                <div className="mt-8 flex items-center justify-end space-x-4">
                    <button 
                        type="button" 
                        onClick={handleCancel}
                        className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Cancelar
                    </button>
                    <button 
                        type="submit" 
                        className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                        Guardar
                    </button>
                </div>
            </form>
        </div>
    );
};

export default AddUser;
