// src/components/vehicles/AddVehicle.tsx
import React, { useState } from 'react';
import { useAppContext } from '../../contexts/GlobalStateContext';

const AddVehicle: React.FC = () => {
  const { addVehicle } = useAppContext();
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [patente, setPatente] = useState('');

  const handleAddVehicle = (e: React.FormEvent) => {
    e.preventDefault();
    addVehicle({ marca, modelo, patente, userId: null });
    setMarca('');
    setModelo('');
    setPatente('');
  };

  return (
    <div className="p-6 bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full bg-white p-8 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 text-center">Agregar Vehículo</h2>
        <form onSubmit={handleAddVehicle} className="space-y-4">
          <div>
            <label htmlFor="marca" className="block text-sm font-medium text-gray-700">
              Marca
            </label>
            <input
              type="text"
              id="marca"
              value={marca}
              onChange={(e) => setMarca(e.target.value)}
              placeholder="Ej. Toyota"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="modelo" className="block text-sm font-medium text-gray-700">
              Modelo
            </label>
            <input
              type="text"
              id="modelo"
              value={modelo}
              onChange={(e) => setModelo(e.target.value)}
              placeholder="Ej. Corolla"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div>
            <label htmlFor="patente" className="block text-sm font-medium text-gray-700">
              Patente
            </label>
            <input
              type="text"
              id="patente"
              value={patente}
              onChange={(e) => setPatente(e.target.value)}
              placeholder="Ej. ABC-1234"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              required
            />
          </div>
          <div className="pt-4">
            <button
              type="submit"
              className="w-full inline-flex justify-center rounded-md bg-indigo-600 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Agregar Vehículo
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddVehicle;
