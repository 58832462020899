export { default as UserProfile } from '../users/visit/UserProfile';
export { default as AddUser } from '../users/visit/AddUser';
export { default as EditUser } from '../users/visit/EditUser';
export { default as ViewUsers } from '../users/visit/ViewUsers';
export { default as DetailUser } from '../users/visit/DetailUser';

export { default as AddUserRol } from '../users/rol/AddUserRol';
export { default as DetailUserRol  } from '../users/rol/DetailUserRol';
export { default as EditUserRol } from '../users/rol/EditUserRol';
export { default as UserRolProfile } from '../users/rol/UserRolProfile';
export { default as ViewUsersRoles } from '../users/rol/ViewUsersRoles';