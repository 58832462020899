import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import QrReader from '../QrReader';

interface CustodySpace {
  id: number;
  location: string;
  capacity: 'Maleta' | 'Bolso de mano';
  occupied: boolean;
}

const DeliverLuggage: React.FC = () => {
  const [scannedCode, setScannedCode] = useState<string | null>(null);
  const [luggageInfo, setLuggageInfo] = useState<CustodySpace | null>(null);
  const navigate = useNavigate(); // Initialize navigate

  // Simulación de búsqueda del espacio de custodia asignado
  const searchCustodySpace = (code: string) => {
    // Aquí puedes integrar una llamada a la API para obtener el espacio asignado
    const mockData: CustodySpace = {
      id: 1,
      location: 'Sector A - 23',
      capacity: 'Maleta',
      occupied: true,
    };
    setLuggageInfo(mockData);
  };

  // Escaneo QR exitoso
  const handleQrScan = (data: string) => {
    setScannedCode(data);
    searchCustodySpace(data); // Busca el espacio con el código QR escaneado
  };

  // Función para confirmar la entrega y liberar el espacio
  const handleDeliver = () => {
    if (luggageInfo) {
      // Liberar espacio (puedes integrar una llamada a la API para actualizar el estado)
      setLuggageInfo({ ...luggageInfo, occupied: false });
      alert('Equipaje entregado y espacio liberado.');
      setScannedCode(null);
      setLuggageInfo(null);
    }
  };

  /*console.log("qrreader type:", type);
  console.log("qrreader spaceId:", spaceId);*/

  return (
    <div className="p-6 bg-gray-50 min-h-screen flex flex-col items-center relative">
      {/* Back Button */}
      <button
        onClick={() => navigate('/Custody/create_section')}
        className="absolute top-4 left-4 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-500"
      >
        Volver
      </button>

      <h1 className="text-2xl font-bold mb-4">Entrega de Equipaje</h1>
      <p className="text-gray-600 mb-8 text-center">
        Escanee el código QR para identificar la ubicación del equipaje y confirmar la entrega.
      </p>

      {/* QR Scanner */}
      {!scannedCode ? (
        <QrReader onScan={(data) => setScannedCode(data)} />
      ) : luggageInfo ? (
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-lg mt-4">
          <h2 className="text-lg font-semibold text-gray-900 mb-4">Detalles del Equipaje</h2>
          <p className="text-sm mb-2"><strong>Ubicación:</strong> {luggageInfo.location}</p>
          <p className="text-sm mb-2"><strong>Capacidad:</strong> {luggageInfo.capacity}</p>
          <p className="text-sm mb-2"><strong>Ocupado:</strong> {luggageInfo.occupied ? 'Sí' : 'No'}</p>
          <button
            onClick={handleDeliver}
            className="mt-4 w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-500"
          >
            Confirmar Entrega y Liberar Espacio
          </button>
        </div>
      ) : (
        <p className="text-gray-500 mt-4">Escaneando...</p>
      )}
    </div>
  );
};

export default DeliverLuggage;
