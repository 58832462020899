// src/components/UserProfile.tsx
import React from 'react';
import { useAppContext } from '../../../contexts/GlobalStateContext';
import useUserDetails from '../../../apis/userDetails';

const UserProfile: React.FC = () => {
  const { user } = useAppContext();
  const userId = user?.user.user_id ?? null;
  const { userDetails, loading, error } = useUserDetails(userId);

  if (loading) return <p className="text-center mt-8 text-gray-500">Cargando información...</p>;
  if (error) return <p className="text-center mt-8 text-red-500">Error: {error}</p>;
  if (!userDetails) return null;

  const {
    correo,
    rut,
    nombre,
    apellidop,
    apellidom,
    edad,
    telefono,
    iglesia,
    acreditado,
    grado,
    zona,
    pais,
    perfil,
    hipertension,
    diabetes,
    celiaco,
    vehiculoPropio,
    esposa,
    rut_esposa,
    nombre_esposa,
    apellidop_esposa,
    apellidom_esposa,
    edad_esposa,
    hipertension_esposa,
    diabetes_esposa,
    celiaco_esposa,
    vehicles,
    custodia,
  } = userDetails;

  const vehicleInfo = vehicles && vehicles.length > 0 ? vehicles[0] : null;
  const custodyInfo = custodia && custodia.find((space) => space.ocupado);

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="max-w-2xl w-full bg-white p-6 rounded-lg shadow-md">
        <div className="text-center mb-8">
          <h3 className="text-2xl font-semibold leading-7 text-gray-900">Información del Usuario</h3>
          <p className="mt-1 text-sm text-gray-500">Detalles personales y de cuenta.</p>
        </div>

        <div className="border-t border-gray-100">
          <dl className="divide-y divide-gray-100">
            {/* Información básica del usuario */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Nombre Completo</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {nombre} {apellidop} {apellidom}
              </dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Edad</dt>
              <dd className="text-sm text-gray-700 col-span-2">{edad}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Correo Electrónico</dt>
              <dd className="text-sm text-gray-700 col-span-2">{correo}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">RUT</dt>
              <dd className="text-sm text-gray-700 col-span-2">{rut}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Perfil</dt>
              <dd className="text-sm text-gray-700 col-span-2">{perfil}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Teléfono</dt>
              <dd className="text-sm text-gray-700 col-span-2">{telefono}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Iglesia</dt>
              <dd className="text-sm text-gray-700 col-span-2">{iglesia}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Acreditado</dt>
              <dd className="text-sm text-gray-700 col-span-2">{acreditado ? 'Sí' : 'No'}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Grado</dt>
              <dd className="text-sm text-gray-700 col-span-2">{grado}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Zona</dt>
              <dd className="text-sm text-gray-700 col-span-2">{zona}</dd>
            </div>
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">País</dt>
              <dd className="text-sm text-gray-700 col-span-2">{pais}</dd>
            </div>

            {/* Condiciones Médicas */}
            <div className="py-4 grid grid-cols-3 gap-4">
              <dt className="text-sm font-medium text-gray-900">Condiciones Médicas</dt>
              <dd className="text-sm text-gray-700 col-span-2">
                {[
                  hipertension && 'Hipertensión',
                  diabetes && 'Diabetes',
                  celiaco && 'Celiaco',
                ].filter(Boolean).join(', ') || 'Ninguna'}
              </dd>
            </div>

            {/* Información del Vehículo */}
            {vehicleInfo && (
              <div className="py-4 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-900">Vehículo Asignado</dt>
                <dd className="text-sm text-gray-700 col-span-2">{vehicleInfo.patente}</dd>
              </div>
            )}

            {/* Información de Custodia */}
            {custodyInfo && (
              <div className="py-4 grid grid-cols-3 gap-4">
                <dt className="text-sm font-medium text-gray-900">Custodia Asignada</dt>
                <dd className="text-sm text-gray-700 col-span-2">
                  ID: {custodyInfo.id}, Ubicación: {custodyInfo.ubicacion}
                </dd>
              </div>
            )}

            {/* Información de la Esposa */}
            {esposa && (
              <>
                <div className="py-4">
                  <h4 className="text-lg font-semibold text-gray-900">Información de la Esposa</h4>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Nombre Completo</dt>
                  <dd className="text-sm text-gray-700 col-span-2">
                    {nombre_esposa} {apellidop_esposa} {apellidom_esposa}
                  </dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">RUT</dt>
                  <dd className="text-sm text-gray-700 col-span-2">{rut_esposa}</dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Edad</dt>
                  <dd className="text-sm text-gray-700 col-span-2">{edad_esposa}</dd>
                </div>
                <div className="py-4 grid grid-cols-3 gap-4">
                  <dt className="text-sm font-medium text-gray-900">Condiciones Médicas</dt>
                  <dd className="text-sm text-gray-700 col-span-2">
                    {[
                      hipertension_esposa && 'Hipertensión',
                      diabetes_esposa && 'Diabetes',
                      celiaco_esposa && 'Celiaca',
                    ].filter(Boolean).join(', ') || 'Ninguna'}
                  </dd>
                </div>
              </>
            )}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
