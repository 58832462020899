//GlobalStateContext.tsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { createCustody } from '../apis/custody/createCustody';
import { getCustodySpaces } from '../apis/custody/getCustodySpaces';

export interface LoginResponse {
  user: {
    user_id: number;
    correo: string;
    rut: string;
    nombre: string;
    apellidop: string;
    apellidom: string;
    edad: number;
    telefono: string;
    iglesia: string;
    acreditado: boolean;
    grado: string;
    zona: string;
    pais: string;
    perfil: string;
    hipertension: boolean;
    diabetes: boolean;
    celiaco: boolean;
    vehiculoPropio: boolean;
    esposa: boolean;
    rut_esposa: string;
    nombre_esposa: string;
    apellidop_esposa: string;
    apellidom_esposa: string;
    edad_esposa: number;
    hipertension_esposa: boolean;
    diabetes_esposa: boolean;
    celiaco_esposa: boolean;
  };
}

export interface User {
  user_id: number;
  correo: string;
  rut: string;
  nombre: string;
  apellidop: string;
  apellidom: string;
  // ... podemos agregar los demás campos si los necesitamos
}

export interface CustodySpace {
  id: number;
  ubicacion: string;
  capacidad: 'Maleta' | 'Bolso de mano';
  ocupado: boolean;
  user?: User | null;
  usuarioId?: number | null;  // Agregamos esto para mantener compatibilidad
}

export interface AssignedCustodySpace {
  idUsuario: number;
}

export interface Vehicle {
  id: number;
  marca: string;
  modelo: string;
  patente: string;
  userId: number | null;
}

interface AppContextInterface {
  isAuthenticated: boolean;
  user: LoginResponse | null;
  login: (email: string, password: string) => Promise<LoginResponse | null>;
  logout: () => void;
  custodySpaces: CustodySpace[];
  addCustodySpace: (newSpace: CustodySpace) => void;
  assignSpace: (id: number) => void;
  freeSpace: (id: number) => void;
  removeCustodySpace: (id: number) => void;
  getCustodySpaces: () => Promise<CustodySpace[]>;
  vehicles: Vehicle[];
  addVehicle: (vehicleData: Omit<Vehicle, 'id'>) => void;
  assignVehicle: (vehicleId: number, userId: number) => void;
  removeVehicle: (id: number) => void;
}

const AppContext = createContext<AppContextInterface | undefined>(undefined);

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<LoginResponse | null>(null);
  const [custodySpaces, setCustodySpaces] = useState<CustodySpace[]>([]);
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const navigate = useNavigate();
  

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para la Custodia de equipaje
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const loadCustodySpaces = async () => {
      try {
        const spaces = await getCustodySpaces(); // Fetch custody spaces from the backend
        console.log("Fetched custody spaces:", spaces); // Log fetched spaces to check the response
        if (Array.isArray(spaces)) {
          // Ensure the fetched data is an array
          setCustodySpaces(spaces);
        } else {
          console.error("Fetched data is not an array:", spaces);
        }
      } catch (error) {
        console.error('Failed to fetch custody spaces:', error);
      }
    };
  
    loadCustodySpaces();
  }, []);

  useEffect(() => {
    console.log('Current custody spaces:', custodySpaces);
}, [custodySpaces]);

  const addCustodySpace = (newSpace: CustodySpace) => {
    setCustodySpaces((prevSpaces) => [...prevSpaces, newSpace]);
  };

  const assignSpace = async (id: number, userData?: User) => {
    try {
        // Primero actualizar el estado local como lo hacías antes
        const updatedSpaces = custodySpaces.map((space) =>
            space.id === id 
                ? { 
                    ...space, 
                    ocupado: true,
                    user: userData ? {
                        user_id: userData.user_id,
                        correo: userData.correo,
                        nombre: userData.nombre,
                        apellidop: userData.apellidop,
                        apellidom: userData.apellidom,
                        rut: userData.rut
                    } : null,
                    usuarioId: userData?.user_id || null
                } 
                : space
        );
        setCustodySpaces(updatedSpaces);
        
        // Luego recargar los espacios desde el servidor
        const spaces = await getCustodySpaces();
        if (Array.isArray(spaces)) {
            console.log('Spaces reloaded after assignment:', spaces);
            setCustodySpaces(spaces); // Actualizar con los datos más recientes
        }
    } catch (error) {
        console.error('Error assigning space:', error);
    }
};

  const freeSpace = (id: number) => {
    const updatedSpaces = custodySpaces.map((space) =>
        space.id === id ? { ...space, ocupado: false, usuarioId: null } : space
    );
    setCustodySpaces(updatedSpaces);
  };

  const removeCustodySpace = (id: number) => {
    const updatedSpaces = custodySpaces.filter((space) => space.id !== id);
    setCustodySpaces(updatedSpaces);
  };

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Vehículos
  
  ++++++++++++++++++++++++++++++++++++++++++*/

  useEffect(() => {
    const storedVehicles = localStorage.getItem('vehicles');
    if (storedVehicles) {
      setVehicles(JSON.parse(storedVehicles));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('vehicles', JSON.stringify(vehicles));
  }, [vehicles]);

  const addVehicle = (vehicleData: Omit<Vehicle, 'id'>) => {
    const newVehicle = { ...vehicleData, id: Date.now() };
    setVehicles((prevVehicles) => [...prevVehicles, newVehicle]);
  };

  const assignVehicle = (vehicleId: number, userId: number) => {
    const updatedVehicles = vehicles.map((vehicle) =>
      vehicle.id === vehicleId ? { ...vehicle, userId } : vehicle
    );
    setVehicles(updatedVehicles);
    localStorage.setItem('vehicles', JSON.stringify(updatedVehicles));
  };

  const removeVehicle = (id: number) => {
    const updatedVehicles = vehicles.filter((vehicle) => vehicle.id !== id);
    setVehicles(updatedVehicles);
  };

  /*+++++++++++++++++++++++++++++++++++++++ 
  
      Funciones para Login y Logout
  
  ++++++++++++++++++++++++++++++++++++++++++*/
  const login = async (correo: string, password: string): Promise<LoginResponse | null> => {
    try {
      const response = await fetch(`${process.env.REACT_APP_RAILWAY_API_URL}/auth/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ correo, password }),
      });

      console.log('url' + `${process.env.REACT_APP_RAILWAY_API_URL}/auth/login`);
      console.log('response ' + JSON.stringify(response));

      if (!response.ok) throw new Error('Login failed');

      const data: LoginResponse = await response.json();
      setUser(data);
      setIsAuthenticated(true);

      return data;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('user');
    navigate('/');
  };

  return (
    <AppContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
        custodySpaces,
        addCustodySpace,
        assignSpace,
        freeSpace,
        removeCustodySpace,
        getCustodySpaces,
        vehicles,
        addVehicle,
        assignVehicle,
        removeVehicle,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) throw new Error('useAppContext debe ser utilizado dentro de un AppProvider');
  return context;
};

export default AppContext;