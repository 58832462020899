import { UserCircleIcon, UserGroupIcon, QrCodeIcon, BuildingOfficeIcon, ClipboardDocumentIcon, BriefcaseIcon, MapIcon, TruckIcon } from '@heroicons/react/24/solid';

export interface MenuItem {
  name: string;
  href: string;
  icon: React.ComponentType<any>;
}

export const menusByRole: { [key: string]: MenuItem[] } = {
  Administrador: [
    { name: 'Perfil', href: '/UserProfile', icon: UserCircleIcon },
    { name: 'Usuarios', href: '/users/list', icon: UserGroupIcon },
    { name: 'Escanear Código QR', href: '/ScanQRModal', icon: QrCodeIcon },
    { name: 'Alojamientos', href: '/Alojamientos', icon: BuildingOfficeIcon },
    { name: 'Despensa e inventario', href: '/Inventary/inventary_list', icon: ClipboardDocumentIcon },
    { name: 'Custodia', href: '/Custody/create_section', icon: BriefcaseIcon },
    { name: 'Vehículos', href: '/Vehicles/list', icon: TruckIcon },
  ],
  'Encargado de Despensa': [
    { name: 'Perfil', href: '/UserProfile', icon: UserCircleIcon },
    { name: 'Despensa e inventario', href: '/Inventary/inventary_list', icon: ClipboardDocumentIcon },
  ],
  'Encargado de Custodia': [
    { name: 'Perfil', href: '/UserProfile', icon: UserCircleIcon },
    { name: 'Custodia', href: '/Custody/create_section', icon: BriefcaseIcon },
  ],
  'Encargado de Vehículos': [
    { name: 'Perfil', href: '/UserProfile', icon: UserCircleIcon },
    { name: 'Vehículos', href: '/Vehicles/list', icon: TruckIcon },
  ],
  Visita: [
    { name: 'Perfil', href: '/UserProfile', icon: UserCircleIcon },
  ],
  // añadir otros roles si es necesario
};
