import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { ClipboardIcon, ArchiveBoxIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { EyeIcon } from '@heroicons/react/24/solid';

const inventoryOptions = [
  { name: 'Ver Productos', href: 'inventary_list', icon: EyeIcon },
  { name: 'Lista de Entregas', href: 'delivery_list', icon: ClipboardIcon },
  { name: 'Entregar Producto', href: 'delivery_product', icon: ArchiveBoxIcon },
  { name: 'Alertas de Inventario', href: 'alert', icon: ExclamationTriangleIcon },
];

const InventoryPage: React.FC = () => {
  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <h1 className="text-2xl font-bold mb-6 text-center">Gestión de Inventario</h1>

      {/* Menú de navegación de opciones en cuadrícula */}
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 divide-y divide-gray-900/5 sm:grid-cols-2 lg:grid-cols-4 sm:divide-x sm:divide-y-0 sm:border-x sm:border-gray-900/5">
          {inventoryOptions.map((option) => (
            <Link
              key={option.name}
              to={option.href}
              className="flex items-center gap-x-2.5 p-4 px-6 text-sm font-semibold text-gray-900 hover:bg-gray-100 sm:justify-center"
            >
              <option.icon aria-hidden="true" className="h-5 w-5 flex-none text-gray-400" />
              {option.name}
            </Link>
          ))}
        </div>
      </div>

      {/* Renderizado del subcomponente según la ruta */}
      <div className="mt-8">
        <Outlet />
      </div>
    </div>
  );
};

export default InventoryPage;
